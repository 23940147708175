import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";
import { useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import Card from "../components/Card";

const NewArrival = () => {
  const { items: data } = useSelector((state) => state.newArrivalProduct);
  return (
    <div className="wrapper">
      <SectionTitle
        title={"Flexible pricing"}
        subtitle={"You check our reasonable and flexible pricing below"}
      />

      <div className="flex flex-col gap-5">
        {data?.map((product) => (
          <Card key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default NewArrival;
