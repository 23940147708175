import { useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import Card from "../components/Card";

import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
  Autoplay,
  Parallax,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";

const BestSelling = () => {
  const { items: data } = useSelector((state) => state.bestSell);

  return (
    <div className="wrapper">
      <SectionTitle
        title={"Exciting features"}
        subtitle={"Join now and enjoy the exciting features from Exord online"}
      />

      {/* {data?.length > 0 &&
        data?.map((product) => <Card key={product.id} product={product} />)} */}

<div className="flex flex-col gap-5">
      {data?.map((product) => (
        <Card key={product.id} product={product} />
      ))}
      </div>
      {/* {data?.length > 0 &&
        data?.map((product) => <Card key={product.id} product={product} />)} */}
    </div>
  );
};

export default BestSelling;
